<template>
  <v-card v-if="sessionPrint.userFullname != null">
    <v-progress-linear style="position: absolute" v-show="loadingSession" :indeterminate="true" class="ma-0"></v-progress-linear>
    <v-card-text class="print-full-width print-font">
      <v-container fluid grid-list-sm pa-0>
        <!-- <v-layout row wrap> -->
        <table class="pa-0 ma-0">
          <thead class="report-header">
            <th>
              <v-layout row wrap>
                <v-flex xs12 pb-0>
                  <h3><u>Daily Progress Note</u></h3>
                </v-flex>
                <v-flex xs2 class="text-xs-left">
                  <img style="object-fit: contain" src="images/logo.jpg" width="100" />
                </v-flex>
                <v-flex xs5 class="text-xs-left">
                  <span class="headline">
                    {{ sessionPrint.clientName }}
                  </span>
                  <br />
                  <span v-if="sessionPrint.clientDob" class="body-1"><strong>D.O.B:</strong> {{ sessionPrint.clientDob | moment("utc", "LL") }}</span>
                </v-flex>
                <v-flex xs5 class="text-xs-right">
                  <span class="title grey--text text--darken-2">{{ sessionPrint.sessionStart | moment("LL") }}</span>
                </v-flex>
                <v-flex xs12>
                  <v-divider></v-divider>
                </v-flex>
              </v-layout>
            </th>
          </thead>
          <tbody>
            <td>
              <v-layout row wrap>
                <v-flex xs8>
                  <v-layout row wrap>
                    <v-flex class="font-weight-medium text-xs-right" xs3>Code:</v-flex>
                    <v-flex xs9>{{ sessionPrint.clientCode }}</v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs3>Diagnosis:</v-flex>
                    <v-flex xs9 style="display: flex; flex-direction: column">
                      <small class="no-wrap" v-for="d in sessionPrint.clientDiagnosis" :key="d.item1"> ({{ d.item1 }}) {{ d.item2 }}</small>
                    </v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs3>Medicaid ID:</v-flex>
                    <v-flex xs9>{{ sessionPrint.memberNo }}</v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs3>Caregiver:</v-flex>
                    <v-flex xs9>{{ sessionPrint.caregiver }} ({{ sessionPrint.caregiverType }})</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout row wrap>
                    <v-flex class="font-weight-medium text-xs-right" xs4>Pos:</v-flex>
                    <v-flex xs8>
                      <span class="text-no-wrap text-truncate">{{ sessionPrint.pos }} ({{ sessionPrint.posNum }})</span>
                    </v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs4>Service code:</v-flex>
                    <v-flex xs8>{{ sessionPrint.service.hcpcs }} <span v-if="isOldCode">BA</span> ({{ sessionPrint.service.description }})</v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs4>Service type:</v-flex>
                    <v-flex xs8>Direct (Face to Face)</v-flex>
                    <!-- <v-flex class="font-weight-medium text-xs-right" xs4>Session type:</v-flex>
              <v-flex xs8>{{ sessionPrint.sessionType }}</v-flex> -->
                    <v-flex class="font-weight-medium text-xs-right" xs4>Provider:</v-flex>
                    <v-flex xs8>{{ sessionPrint.userFullname }} ({{ sessionPrint.userRol }})</v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs4>Time IN:</v-flex>
                    <v-flex xs8>
                      <v-icon color="green" small>fa-sign-in-alt</v-icon>
                      {{ sessionPrint.sessionStart | moment("LT") }}
                    </v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs4>Time OUT:</v-flex>
                    <v-flex xs8>
                      <v-icon color="red" small>fa-sign-out-alt</v-icon>
                      {{ sessionPrint.sessionEnd | moment("LT") }}
                    </v-flex>
                    <v-flex class="font-weight-medium text-xs-right" xs4>Rendered Units:</v-flex>
                    <v-flex xs8>
                      <v-icon small>fa-star</v-icon>
                      {{ sessionPrint.totalUnits.toLocaleString() }}
                      <v-icon small>fa-clock</v-icon>
                      {{ (sessionPrint.totalUnits / 4).toLocaleString() }}
                    </v-flex>
                    <!-- <v-flex class="font-weight-medium text-xs-right" xs4>Drive time:</v-flex>
              <v-flex xs8>
                <v-icon small>fa-car</v-icon>
                {{ sessionPrint.driveTime.toLocaleString() }}
              </v-flex> -->
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-card flat>
                    <v-divider></v-divider>
                    <table class="table-horizontal" width="100%">
                      <!-- Section -->
                      <template v-if="sessionPrint.sessionTypeNum === 2">
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%">
                            <v-icon small>{{ sessionPrint.caregiverTraining.caregiverTrainingObservationFeedback ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                          </td>
                          <td class="pl-1">Observation of recipients's interaction with parent/caregiver/others</td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%">
                            <v-icon small>
                              {{ sessionPrint.caregiverTraining.caregiverTrainingParentCaregiverTraining ? "fa-check-circle" : "far fa-circle" }}
                            </v-icon>
                          </td>
                          <td class="pl-1">Parent/Caregiver training</td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%">
                            <v-icon small>{{ sessionPrint.caregiverTraining.caregiverTrainingCompetencyCheck ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                          </td>
                          <td class="pl-1">Competency check of caregiver</td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Others:</td>
                          <td class="pl-1" v-html="breakLine(sessionPrint.caregiverTraining.caregiverTrainingOther)"></td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Summary:</td>
                          <td class="pl-1" v-html="breakLine(sessionPrint.caregiverTraining.caregiverTrainingSummary)"></td>
                        </tr>
                      </template>
                      <template v-if="sessionPrint.sessionTypeNum === 3">
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%">
                            <!-- <v-icon small>{{ sessionPrint.supervisionNote.isDirectSession ? "fa-check-circle" : "far fa-circle" }}</v-icon> -->
                          </td>
                          <td class="pl-1">
                            <v-icon small>{{ sessionPrint.supervisionNote.isDirectSession ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            Direct session
                          </td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Work with:</td>
                          <td class="pl-1">
                            <span v-for="w in sessionSupervisionWorkWithCodes" :key="'w-' + w.text"> {{ w.text }} | </span>
                          </td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Summary:</td>
                          <td class="pl-1">
                            <v-icon small>{{ sessionPrint.supervisionNote.briefObservation ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            Observation and feedback regarding interaction of BCaBA<br />
                            <v-icon small>{{ sessionPrint.supervisionNote.briefReplacement ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            Replacement/Acquisition Programs Implementation monitoring<br />
                            <v-icon small>{{ sessionPrint.supervisionNote.briefGeneralization ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            Generalization of Treatment<br />
                            <v-icon small>{{ sessionPrint.supervisionNote.briefBCaBaTraining ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            BCaBA training and oversight<br />
                            <v-icon small>{{ sessionPrint.supervisionNote.briefInService ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            In-Service Education Training (for session participants)<br />
                            <div style="padding-left: 20px">
                              Subject:
                              {{ sessionPrint.supervisionNote.briefInServiceSubject || "N/A" }}
                            </div>
                            <v-icon small>{{ sessionPrint.supervisionNote.briefOther ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            Other<br />
                            <div style="padding-left: 20px" v-html="breakLine(sessionPrint.supervisionNote.briefOtherDescription)"></div>
                          </td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Oversight:</td>
                          <td class="pl-1">
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightFollowUpBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Follow up upon recommendations from previous reassessment
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightFollowUp }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightDesigningBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Designing, implementing and monitoring program for client
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightDesigning }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightContributingBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Contributing with Behavioral Assessment
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightContributing }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightAnalyzingBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Analyzing data
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightAnalyzing }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightGoalsBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Goals progress evidenced in client performance
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightGoals }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightMakingDecisionsBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Making decisions about progress
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightMakingDecisions }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightModelingBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Modeling technical, professional and ethical behavior
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightModeling }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightResponseBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Response to feedback from Lead Analyst
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightResponse }}</strong>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                              <v-flex xs8>
                                <v-icon small>{{ sessionPrint.supervisionNote.oversightOverallBool ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                                Overall Evaluation in session
                              </v-flex>
                              <v-flex xs4 text-xs-right>
                                <strong>{{ sessionPrint.supervisionNote.oversightOverall }}</strong>
                              </v-flex>
                            </v-layout>
                          </td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Comments related:</td>
                          <td class="pl-1" v-html="breakLine(sessionPrint.supervisionNote.commentsRelated || 'N/A')"></td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Recommendations:</td>
                          <td class="pl-1" v-html="breakLine(sessionPrint.supervisionNote.Recommendations || 'N/A')"></td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%">
                            <v-icon small>{{ sessionPrint.supervisionNote.validation ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                          </td>
                          <td class="pl-1">Validations: Previous agreement for times and place of next visit?</td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Next schedule:</td>
                          <td class="pl-1">
                            <span v-if="sessionPrint.supervisionNote.nextScheduledDate">{{ sessionPrint.supervisionNote.nextScheduledDate | moment("utc", "LL") }}</span>
                            <span v-else>N/A</span>
                          </td>
                        </tr>
                      </template>
                      <template v-if="sessionPrint.sessionTypeNum === 1">
                        <tr class="no-page-break grey lighten-3">
                          <td class="font-weight-medium text-xs-right">SUMMARY</td>
                          <td v-if="!isSupervision">&nbsp;(Include Environmental changes)</td>
                        </tr>
                        <tr class="no-page-break">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top"></td>
                          <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.progressNotes || 'N/A')"></td>
                        </tr>
                        <template
                          v-if="
                            sessionPrint.sessionNote.individualPresentClient ||
                            sessionPrint.sessionNote.individualPresentParent ||
                            sessionPrint.sessionNote.individualPresentRendering ||
                            sessionPrint.sessionNote.individualPresentSupervisor ||
                            sessionPrint.sessionNote.otherParticipant ||
                            sessionPrint.sessionNote.reasonIfNoPresent
                          "
                        >
                          <tr class="no-page-break grey lighten-3">
                            <td class="font-weight-medium text-xs-right">INDIVIDUALS</td>
                            <td>&nbsp;(Other participants and reasons)</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <v-icon small>{{ sessionPrint.sessionNote.individualPresentClient ? "fa-check-circle" : "far fa-circle" }}</v-icon> Client &nbsp;&nbsp;
                              <v-icon small>{{ sessionPrint.sessionNote.individualPresentParent ? "fa-check-circle" : "far fa-circle" }}</v-icon> Parent/Guardian &nbsp;&nbsp;
                              <v-icon small>{{ sessionPrint.sessionNote.individualPresentRendering ? "fa-check-circle" : "far fa-circle" }}</v-icon> Rendering/Provider &nbsp;&nbsp;
                              <v-icon small>{{ sessionPrint.sessionNote.individualPresentSupervisor ? "fa-check-circle" : "far fa-circle" }}</v-icon> Supervisor
                            </td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Other participants:</td>
                            <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.otherParticipant || 'N/A')"></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Reason if not present:</td>
                            <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.reasonIfNoPresent || 'N/A')"></td>
                          </tr>
                        </template>
                        <!-- End Section -->
                        <tr class="no-page-break grey lighten-3" v-if="sessionPrint.sessionNote.sessionResult === 'NA'">
                          <td class="font-weight-medium text-xs-right">CAREGIVER NOTES</td>
                          <td></td>
                        </tr>
                        <!-- <tr class="no-page-break">
                  <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top;">
                    Caregiver:
                  </td>
                  <td class="pl-1">{{ sessionPrint.caregiver }} ({{ sessionPrint.caregiverType }})</td>
                </tr> -->
                        <tr class="no-page-break" v-if="sessionPrint.sessionNote.sessionResult === 'NA'">
                          <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top"></td>
                          <td class="pl-1" v-html="breakLine(sessionPrint.caregiverNote || 'None')"></td>
                        </tr>
                        <template v-if="!isSupervision">
                          <tr class="no-page-break grey lighten-3">
                            <td class="font-weight-medium text-xs-right">RISKY BEHAVIOR</td>
                            <td></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top"></td>
                            <td class="pl-1">
                              {{ sessionPrint.sessionNote.riskBehavior == "Undefined" || sessionPrint.sessionNote.riskBehavior == "NA" ? "None" : sessionPrint.sessionNote.riskBehavior }}
                            </td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">
                              <v-icon small>{{ sessionPrint.sessionNote.crisisInvolved ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                            </td>
                            <td class="pl-1">
                              Crisis involved<br />
                              <div v-html="breakLine(sessionPrint.sessionNote.crisisInvolvedExplain)"></div>
                            </td>
                          </tr>
                          <tr class="no-page-break grey lighten-3">
                            <td colspan="2" class="font-weight-medium pl-5">CLINICAL TREATMENT IMPLEMENTATION</td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Objetives:</td>
                            <td class="pl-1">Decrease maladaptive behavior while increasing socially acceptable behavior/functional equivalent behaviors/replacement behavior.</td>
                          </tr>
                          <tr class="no-page-break" v-for="p in sessionPrint.sessionNote.problems" :key="p.problem">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">{{ p.problem }}:</td>
                            <td class="pl-1">
                              <table class="table-print">
                                <tr>
                                  <th width="50%">During which activity Bx. Occurred (Antecedents):</th>
                                  <th width="50%">Interventions linked to the Behavior-based Functions:</th>
                                </tr>
                                <tr>
                                  <td width="50%" v-html="breakLine(p.duringWichActivities || 'No observed')"></td>
                                  <td width="50%">
                                    <div v-html="breakLine(p.replacementInterventionsUsed || 'N/A')"></div>
                                    <v-divider class="pb-1"></v-divider>
                                    <strong><u>Replacement Programs linked to the Behavior’s Functions</u></strong
                                    ><br />
                                    <template v-for="r in p.sessionProblemReplacements">
                                      <v-icon small :key="p + r">fa-check-circle</v-icon>
                                      {{ r }}<br :key="'br' + p + r" />
                                    </template>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          <tr class="no-page-break grey lighten-3">
                            <td class="font-weight-medium text-xs-right">REINFORCERS</td>
                            <td></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Edibles:</td>
                            <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.reinforcersEdibles || 'None')"></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Non-edibles:</td>
                            <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.reinforcersNonEdibles || 'None')"></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Others:</td>
                            <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.reinforcersOthers || 'None')"></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Result:</td>
                            <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.reinforcersResult || 'None')"></td>
                          </tr>
                          <tr class="no-page-break grey lighten-3">
                            <td class="font-weight-medium text-xs-right">PARTICIPATION LEVEL</td>
                            <td></td>
                          </tr>
                          <tr class="no-page-break">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top"></td>
                            <td class="pl-1">
                              {{ sessionPrint.sessionNote.participationLevel == "Undefined" ? "None" : sessionPrint.sessionNote.participationLevel }}
                            </td>
                          </tr>
                          <template v-if="sessionPrint.sessionNote.sessionResult !== 'NA'">
                            <tr class="no-page-break grey lighten-3">
                              <td class="font-weight-medium text-xs-right">SESSION RESULTS</td>
                              <td></td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top"></td>
                              <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.sessionResult || 'None')"></td>
                            </tr>
                          </template>
                          <template v-if="sessionPrint.userRolShort != 'tech'">
                            <tr class="no-page-break grey lighten-3">
                              <td class="font-weight-medium text-xs-right">SERVICES PROVIDED</td>
                              <td></td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.summaryDirectObservation ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">Direct observation/Data collection/Probing</td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.summaryObservationFeedback ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">Observation of recipients's interaction with parent/caregiver/others</td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.summaryImplementedReduction ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">Implemented reduction programs</td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.summaryImplementedReplacement ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">Implemented replacement programs</td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.summaryGeneralization ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">Generalization of treatment</td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.summaryCommunication ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">Communication/Coordination of care with other services professionals</td>
                            </tr>
                            <tr class="no-page-break">
                              <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Others:</td>
                              <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.summaryOther || 'N/A')"></td>
                            </tr>
                          </template>

                          <template
                            v-if="
                              sessionPrint.userRolShort != 'tech' &&
                              (sessionPrint.sessionNote.supervision1 ||
                                sessionPrint.sessionNote.supervision2 ||
                                sessionPrint.sessionNote.supervision3 ||
                                sessionPrint.sessionNote.supervision4 ||
                                sessionPrint.sessionNote.supervision5 ||
                                sessionPrint.sessionNote.supervision6 ||
                                sessionPrint.sessionNote.supervision7 ||
                                sessionPrint.sessionNote.supervisionOther)
                            "
                          >
                            <tr class="no-page-break grey lighten-3">
                              <td class="font-weight-medium text-xs-right">CASE SUPERVISION</td>
                              <td></td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision1">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision1 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT can describe behavior and environment in observable and measurable terms during the session.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision2">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision2 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT was able to conduct a preference assessment during the session.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision3">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision3 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT was able to follow the schedule of reinforcement during the session.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision4">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision4 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT was able to implement discrete-trial-teaching procedure during the session.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision5">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision5 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT was able to implement prompt levels of assistance and prompt fading procedures during the session.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision6">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision6 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT was able to implement intervention and or replacement programs linked to the functional equivalent to the target behavior.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervision7">
                              <td class="font-weight-medium text-xs-right" width="20%">
                                <v-icon small>{{ sessionPrint.sessionNote.supervision7 ? "fa-check-circle" : "far fa-circle" }}</v-icon>
                              </td>
                              <td class="pl-1">The RBT was able to implement Extinction procedures as needed.</td>
                            </tr>
                            <tr class="no-page-break" v-if="sessionPrint.sessionNote.supervisionOther">
                              <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Others:</td>
                              <td class="pl-1" v-html="breakLine(sessionPrint.sessionNote.supervisionOther || 'N/A')"></td>
                            </tr>
                          </template>

                          <tr class="no-page-break grey lighten-3" v-if="!noDataBehaviorCollection || !noDataReplacementCollection">
                            <td class="font-weight-medium text-xs-right">DATA COLLECTION</td>
                            <td></td>
                          </tr>
                          <tr class="no-page-break" v-if="!noDataBehaviorCollection">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Target Behaviors to Decrease:</td>
                            <td class="pl-1">
                              <table class="table-print">
                                <tr v-for="b in collection.collectBehaviors" :key="b.sessionCollectBehaviorV2Id">
                                  <td width="50%">
                                    {{ b.behavior.problemBehaviorDescription }}
                                  </td>
                                  <td width="50%">
                                    {{ getTotalBehaviors(b) }}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr class="no-page-break" v-if="!noDataReplacementCollection">
                            <td class="font-weight-medium text-xs-right" width="20%" style="vertical-align: top">Replacement Programs:</td>
                            <td class="pl-1">
                              <table class="table-print">
                                <tr v-for="b in collection.collectReplacements" :key="b.sessionCollectReplacementV2Id">
                                  <td width="50%">
                                    {{ b.replacement.replacementProgramDescription }}
                                  </td>
                                  <td width="50%">
                                    {{ getTotalReplacement(b) }}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </table>
                  </v-card>
                </v-flex>
                <v-flex xs12>
                  <div class="sign">
                    <v-layout row wrap>
                      <v-flex class="pt-5" text-xs-center xs6>
                        <div style="max-height: 50px; height: 50px" class="mb-2">
                          <img style="height: 100%" :src="sessionPrint.userSign.sign" />
                        </div>
                        <v-divider></v-divider>
                        <label>
                          Signed by
                          <strong>
                            {{ sessionPrint.userFullname }}
                          </strong>
                          on {{ sessionPrint.sessionStart | moment("LL") }}
                        </label>
                        <br />
                        <small>{{ sessionPrint.userLicense }}</small>
                      </v-flex>

                      <v-flex
                        class="pt-5"
                        text-xs-center
                        xs6
                        v-if="(sessionPrint.analyst || hasLeadSign) && (sessionPrint.userFirstname != sessionPrint.analyst.firstname || sessionPrint.userLastname != sessionPrint.analyst.lastname)"
                      >
                        <!-- <span v-if="sessionPrint.userFirstname != sessionPrint.analyst.firstname || sessionPrint.userLastname != sessionPrint.analyst.lastname"> -->
                        <div style="max-height: 50px; height: 50px" class="mb-2">
                          <img style="height: 100%" :src="sessionPrint.analystSign.sign" />
                        </div>
                        <v-divider></v-divider>
                        <div>
                          <label>
                            Reviewed by
                            <strong>{{ sessionPrint.analyst.firstname }} {{ sessionPrint.analyst.lastname }}</strong>
                            on
                            {{ sessionPrint.sessionStart | moment("add", "1 days", "LL") }}
                          </label>
                          <br />
                          <small>{{ sessionPrint.analyst.licenseNo }}</small>
                        </div>
                        <!-- </span> -->
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex xs12 v-if="isSupervision">
                  <div class="sign">
                    <v-layout row wrap>
                      <v-flex class="pt-5" text-xs-center xs6>
                        <div style="max-height: 50px; height: 50px" class="mb-2">
                          <img style="height: 100%" :src="sessionPrint.caregiverSign.sign" />
                        </div>
                        <v-divider></v-divider>
                        <label>
                          Signed by
                          <strong> {{ sessionPrint.caregiver }} ({{ sessionPrint.caregiverType }}) </strong>
                          on {{ sessionPrint.caregiverSign.signedDate | moment("LL") }}
                        </label>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </td>
          </tbody>
        </table>
        <!-- </v-layout> -->
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import sessionServicesApi from "@/services/api/SessionServices";
import masterTableApi from "@/services/api/MasterTablesServices";

export default {
  props: {
    sessionId: {
      type: Number,
      required: true
    },
    behaviorAnalysisCodeId: {
      type: Number,
      required: false
    }
  },

  computed: {
    hasLeadSign() {
      if (!this.sessionPrint.analyst) return false;
      return this.sessionPrint.userId !== this.sessionPrint.analyst.userId;
    },
    noDataBehaviorCollection() {
      const noData = this.collection.collectBehaviors.filter((s) => s.noData);
      return noData.length == this.collection.collectBehaviors.length;
    },
    noDataReplacementCollection() {
      const noData = this.collection.collectReplacements.filter((s) => s.noData);
      return noData.length == this.collection.collectReplacements.length;
    },
    isSupervision() {
      return !this.sessionPrint || this.sessionPrint.service.hcpcs == "97153XP" || this.sessionPrint.service.hcpcs == "97155XP";
    },
    isOldCode() {
      return !this.sessionPrint || this.sessionPrint.service.hcpcs.startsWith("H");
    }
  },

  data() {
    return {
      loadingSession: false,
      sessionPrint: {
        clientName: null,
        sessionStart: this.$moment(),
        sessionEnd: this.$moment(),
        userFullname: null,
        userRol: null,
        totalUnits: 0,
        clientCode: null,
        pos: null,
        posNum: 0,
        sessionType: null,
        sessionTypeNum: 0,
        service: null,
        serviceDescription: null,
        driveTime: 0,
        caregiver: null,
        caregiverType: null,
        caregiverNote: null,
        caregiverTraining: {
          caregiverTrainingObservationFeedback: false,
          caregiverTrainingParentCaregiverTraining: false,
          caregiverTrainingCompetencyCheck: false,
          caregiverTrainingOther: null,
          CaregiverTrainingSummary: null
        },
        supervisionNote: {
          isDirectSession: false,
          workWith: 0,
          briefObservation: false,
          briefReplacement: false,
          briefGeneralization: false,
          briefBCaBaTraining: false,
          briefInService: false,
          briefInServiceSubject: null,
          briefOther: false,
          briefOtherDescription: null
        },
        sessionNote: {
          riskBehavior: null
        },
        analyst: {
          userId: 0
        }
      },
      collection: {
        collectBehaviors: [],
        collectReplacements: []
      },
      sessionSupervisionWorkWithCodes: []
      //      sessionExtraInfo: {
      //        lead: {
      //          userId: null
      //        }
      //      }
    };
  },

  mounted() {
    if (!this.sessionId) this.close();
    this.loadSessionSupervisionWorkWithCodes();
    this.loadSessionData();
  },

  methods: {
    async loadSessionData() {
      try {
        this.loadingSession = true;
        let sessionPrint = await sessionServicesApi.getSessionForPrint(this.sessionId, this.behaviorAnalysisCodeId);
        if (typeof sessionPrint.sessionStart !== "undefined") {
          let d1 = this.$moment(sessionPrint.sessionStart).local();
          let d2 = this.$moment(sessionPrint.sessionEnd).local();
          sessionPrint.sessionStart = d1;
          sessionPrint.sessionEnd = d2;
          this.sessionPrint = sessionPrint;
          if (this.sessionPrint.sessionTypeNum === 3) {
            this.sessionSupervisionWorkWithCodes.forEach((c) => {
              const a = (c.value & this.sessionPrint.supervisionNote.workWith) !== 0;
              c.active = a;
            });
            this.sessionSupervisionWorkWithCodes = this.sessionSupervisionWorkWithCodes.filter((w) => w.active);
          }
          this.collection.collectBehaviors = await sessionServicesApi.getCollectBehaviors(this.sessionId);
          this.collection.collectReplacements = await sessionServicesApi.getCollectReplacements(this.sessionId);
          //this.sessionExtraInfo = await sessionServicesApi.getSessionPrintExtraInfo(this.sessionId);
          console.log(this.sessionPrint);
        }
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loadingSession = false;
      }
    },

    async loadSessionSupervisionWorkWithCodes() {
      try {
        this.sessionSupervisionWorkWithCodes = await masterTableApi.getSessionSupervicionWorkWithCodes();
      } catch (error) {
        this.$toast.error(error.message || error);
      }
    },

    getTotalBehaviors(b) {
      if (b.noData) return "No data collected";
      if (!b.behavior.isPercent) return b.total;
      let total = b.total;
      let completed = b.completed != null ? b.completed : 0;
      let percent = total == 0 ? 0 : (completed / total) * 100;
      return `${percent.toFixed(0)}%`;
    },

    getTotalReplacement(c) {
      if (c.noData) return "No data collected";
      let total = c.total;
      let completed = c.completed;
      let percent = total == 0 ? 0 : (completed / total) * 100;
      return `${percent.toFixed(0)}%`;
    },

    breakLine(s) {
      return !s ? null : s.replace(/\n/g, "<br/>");
    }
  }
};
</script>

<style></style>
